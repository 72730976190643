import React from 'react'

function TextBlock({ heading, paragraph1, paragraph2, paragraph3 }) {
  return (
    <section className="text-block">
      <div className="container">
        <div className="text-block__inner">
          <h2
            className="text-block__heading 
                        text--3
                        underline"
          >
            {heading}
          </h2>
          <div className="text-block__content">
            <p className="text-block__paragraph text--5">{paragraph1}</p>

            {paragraph2 ? (
              <p className="text-block__paragraph text--5">{paragraph2}</p>
            ) : null}
            {paragraph3 ? (
              <p className="text-block__paragraph text--5">{paragraph3}</p>
            ) : null}
          </div>
        </div>
      </div>
    </section>
  )
}

export default TextBlock
