import React from 'react'

function List(props) {
  const items = props.data.map(item => (
    <li className="list-section__item" key={item.id}>
      <p className="list-section__paragraph text--5">{item.text}</p>
    </li>
  ))

  return (
    <section
      className={
        'list-section ' + (props.additionalClass ? props.additionalClass : '')
      }
    >
      <div className="container">
        <div className="list-section__inner">
          <ol className="list-section__list">{items}</ol>
        </div>
      </div>
    </section>
  )
}

export default List
