import React from 'react'

// import { Link } from 'gatsby'

function WorkWithUs(props) {
  function openChat() {
    if (window.continuallyAPI) {
      window.continuallyAPI.startConversation()
    }
  }

  return (
    <section className="work-with-us">
      <div className="container">
        <h2 className="work-with-us__heading text--3">
          {props.title ? props.title : 'Ready to Start Your Project?'}
        </h2>
        <div className="work-with-us__cta-wrapper">
          <button
            className="work-with-us__cta
                        btn
                        btn__hero
                        btn--blue
                        text--6
                        continually"
            onClick={openChat}
          >
            {props.ctaText
              ? props.ctaText
              : props.isDevelopers
              ? `Hire Developers`
              : `Let's Go!`}
          </button>
        </div>
      </div>
    </section>
  )
}

export default WorkWithUs
