import React from 'react'

function Technologies(props) {
  return (
    <section
      className={'technologies ' + (props.grey ? 'technologies--grey' : '')}
    >
      <div className="container">
        <div className="technologies__inner">
          <div className="technologies__content">
            <h2 className="technologies__heading text--3">
              <span className="technologies__heading-undelrine underline">
                Technologies{' '}
              </span>{' '}
              <span className="technologies__heading-text">We Use </span>{' '}
            </h2>{' '}
            <p className="technologies__paragraph text--5">
              Emersoft is at the forefront of AI development. We harness top-tier technology to ensure your business stays ahead of the curve.{' '}
            </p>{' '}
          </div>

          <div className="technologies__img-wrapper">
            <img
              src="https://cdn.emersoft.co/emersoft-website/logos/emersoft-technologies.png"
              alt="Web technologies"
              className="technologies__img"
            />
          </div>
        </div>{' '}
      </div>{' '}
    </section>
  )
}

export default Technologies
