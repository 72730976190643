import React from 'react'

function ContainerImg(props) {
  return (
    <section
      className={
        'section-img ' + (props.additionalClass ? props.additionalClass : '')
      }
    >
      <div className="container">
        <img src={props.img} alt={props.alt} className="section-img__img" />
      </div>
    </section>
  )
}

export default ContainerImg
