import React from 'react'

import SEO from '../../components/seo'

import Layout from '../../components/layout'

import SectionHeader from '../../components/SectionHeader/SectionHeader'
import ServiceDescription from '../../components/ServiceDescription/ServiceDescription'
import ContainerImg from '../../components/ContainerImg/ContainerImg'
import TextBlock from '../../components/TextBlock/TextBlock'
import List from '../../components/List/List'
import Testimonial from '../../components/Testimonial/Testimonial'
import Technologies from '../../components/Technologies/Technologies'
import WorkWithUs from '../../components/WorkWithUs/WorkWithUs'
import BottomNav from '../../components/BottomNav/BottomNav'

const CustomWebsites = () => {
  const ourList = [
    {
      id: 1,
      text:
        'First, we have a thorough interview to learn about you/the company more.',
    },
    {
      id: 2,
      text:
        'We plan out the scope and the structure of your website and/or marketing.',
    },
    {
      id: 3,
      text: 'Our team starts to work based on the gathered information.',
    },
    {
      id: 4,
      text: 'We carry out tests to ensure everything is flawless.',
    },
    {
      id: 5,
      text: 'You get the end-proposal and we apply revisions if necessary.',
    },
  ]

  return (
    <Layout>
      <SEO
        title="Websites/Design"
        keywords={[
          `Emersoft`,
          `application`,
          `react`,
          `London`,
          `digital agency`,
        ]}
      />
      <SectionHeader>
        A few seconds and they click away. <br />
        Do you want to keep taking the risk?
      </SectionHeader>
      <ServiceDescription />
      <ContainerImg
        img="https://cdn.emersoft.co/emersoft-website/services_websites/custom-websites-kanban.jpg"
        alt="Kanban board"
      />
      <TextBlock
        heading="Our Process"
        paragraph1="Effective communication is the key. We won't start until we know exactly what's the best custom - solution and what types of customers buy from you."
      />
      <List data={ourList} />
      <Testimonial
        paragraph="Guys have done an excellent job with our new Unitruck website. It looks great, it’s super fast and our e-commerce shop is nicely integrated. Excellent communication and quick execution!"
        projectTitle="Unitruck"
        author="Carl Schalch"
        authorRole="Managing Director"
        link="/our-work/unitruck"
      />
      <Technologies />
      <ContainerImg
        img="https://cdn.emersoft.co/emersoft-website/services_websites/custom-websites-code.jpg"
        alt="Code"
        additionalClass="section-img--bottom"
      />
      <WorkWithUs />
      <BottomNav
        additionalClass="bottom-nav--border-top"
        heading="See More"
        firstLink="/our-work"
        firstHeading="Projects"
        secondLink="/about"
        secondHeading="About Us"
      />
    </Layout>
  )
}

export default CustomWebsites
